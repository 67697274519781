import React from 'react'
import './navbar.css'

function navbar() {
    return(
        <nav className = 'bg-white-500 p-4'>
            <div className= "flex items-center justify-between w-full">
                <a href='/'>
                    <img className = "logo" src={"/file.jpg"}/> 
                </a>
                <ul className ="flex space-x-3 md:space-x-4 lg:space-x-6 flex-wrap-nowrap">
                    <li><a href="/team" className ="text-black">OUR TEAM</a></li>
                    <li><a href="/about" className ="text-black">ABOUT US</a></li>
                </ul>
                <button className="newsletter text-white font-bold py-2 px-2 md:px-4 lg:px-5 rounded hover:bg-blue-900 transition duration-200">
                    <a href='/preorder'>
                        Preorder Now
                    </a>
                </button>
            </div>
        </nav>

    )
}
export default navbar;
