import './home.css'
import { InstagramEmbed } from 'react-social-media-embed';

function Home() {
    return (
        <div className="HomePage">
            <div className="section h-screen flex flex-col md:flex-row items-center justify-center bg-gray-200 relative">
                <div id="titleContainer" className="text-left sm:w-3/4 md:w-1/2 lg:w-1/3 px-6">
                    <h1 className="text-4xl md:text-4xl lg:text-6xl font-bold leading-tight md:leading-snug lg:leading-snug">SMALLER <br className="block md:hidden" /> SMARTER <br className="block md:hidden" /> BETTER</h1>
                    <p className="text-lg md:text-xl text-gray-600">See for yourself.</p>
                </div>
                <img src={"/8.png"} className="absolute right-0 top-1/2 transform -translate-y-1/2 w-1/3 md:w-1/4 lg:w-1/5 max-w-custom-percent object-contain" />
                {/* <div id="imageSelector">
                    <div className='option active'></div>
                    <div className='option'></div>
                    <div className='option'></div>
                    <div className='option'></div>
                    <div className='option'></div>
                </div> */}
            </div>
            <div className="  min-h-screen section bg-gray-100">
                <div className="py-32 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-4/5 mx-auto flex">
                    {/*Why Nanotags */}
                    <div className="bg-white p-6 shadow-lg rounded-lg">
                        <h1 className="text-2xl font-bold mb-2">Why Nanotags?</h1>
                        <p className="text-gray-600">
                            Discover how eNano is redefining sustainability in IoT with our standout features.
                        </p>
                    </div>

                    {/*1-nanoTags: Small is the New Powerful*/}
                    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                        <div className="relative h-80 group">
                            <img src="/specifications_env.jpg" alt="Nano Tags" className="w-full h-full object-cover" />

                            <div className="absolute bottom-0 left-0 w-full bg-blue-900 bg-opacity-75 text-white p-4 transition-all duration-300 ease-in-out max-h-14 group-hover:max-h-40 overflow-hidden">
                                <h2 className="text-md font-semibold cursor-pointer">
                                    nanoTags: Small is the New Powerful
                                </h2>
                                <p className="text-sm mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                                    Introducing nanoTags, the world’s smallest smart tags—14 times smaller, infinitely smarter. Seamlessly track your most essential items.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*2-Power That Never Quits*/}
                    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                        <div className="relative h-80 group">
                            <img src="/no-battery-icon-or-logo-forbidden-using-battery-is-not-allowed-illustration-free-vector.png" alt="Power That Never Quits" className="w-full h-full object-cover" />

                            <div className="absolute bottom-0 left-0 w-full bg-blue-900 bg-opacity-75 text-white p-4 transition-all duration-300 ease-in-out max-h-14 group-hover:max-h-40 overflow-hidden">
                                <h2 className="text-md font-semibold cursor-pointer">
                                    Power That Never Quits
                                </h2>
                                <p className="text-sm mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                                    Say farewell to batteries and charging cables. nanoTags are completely battery-free, powered by revolutionary technology.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*3-Instant Alerts, Timely Action*/}
                    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                        <div className="relative h-80 group">
                            <img src="/notif.png" alt="Instant Alerts" className="w-full h-full object-cover" />

                            <div className="absolute bottom-0 left-0 w-full bg-blue-900 bg-opacity-75 text-white p-4 transition-all duration-300 ease-in-out max-h-14 group-hover:max-h-40 overflow-hidden">
                                <h2 className="text-md font-semibold cursor-pointer">
                                    Instant Alerts, Timely Action
                                </h2>
                                <p className="text-sm mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                                    Receive real-time notifications the moment you step away from your valuables, so you can take action instantly.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*4- Seamless Setup, Effortless Use*/}
                    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                        <div className="relative h-80 group">
                            <img src="/tools_env.jpg" alt="Seamless Setup" className="w-full h-full object-cover" />

                            <div className="absolute bottom-0 left-0 w-full bg-blue-900 bg-opacity-75 text-white p-4 transition-all duration-300 ease-in-out max-h-14 group-hover:max-h-40 overflow-hidden">
                                <h2 className="text-md font-semibold cursor-pointer">
                                    Seamless Setup, Effortless Use
                                </h2>
                                <p className="text-sm mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                                    We’ve chosen trusted 3M adhesive strips to ensure your nanoTag stays securely in place. Just peel, stick, and forget.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*5-Leave the Details to AI*/}
                    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                        <div className="relative h-80 group">
                            <img src="/ai_butler.png" alt="AI Butler" className="w-full h-full object-cover" />

                            <div className="absolute bottom-0 left-0 w-full bg-blue-900 bg-opacity-75 text-white p-4 transition-all duration-300 ease-in-out max-h-14 group-hover:max-h-40 overflow-hidden">
                                <h2 className="text-md font-semibold cursor-pointer">
                                    Leave the Details to AI
                                </h2>
                                <p className="text-sm mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                                    eNano’s AI Butler anticipates your needs and sends gentle reminders, so you can stay focused on what truly matters.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Specifications*/}
            <div className="min-h-screen section bloo flex flex-col items-center justify-center relative">
                <div className="gallery-content text-center w-4/5 mx-auto">
                    <h1 className="text-3xl font-bold mb-6 p-auto">SPECIFICATIONS</h1>
                    <div className="gallery flex flex-col md:flex-row items-center justify-center gap-6">
                        <div className="gallery-card bg-white shadow-lg rounded-lg p-6 w-full md:w-2/3 lg:w-1/2">
                            <img src={"/croppedGlasses.jpg"} alt="Nanotags" className="w-auto h-auto rounded-md mb-4 object-cover" />
                            <h1 className="text-2xl font-bold mb-2">nanoTags</h1>
                            <hr class="w-3/5 h-1 bg-gray-200 border-0 rounded"></hr>
                            <div className="gallery-card-text flex flex-col items-center text-center mt-3">
                                <p>6x20.8x3.5mm</p>
                                <p>500mg Weight</p>
                                <p>IP68 waterproof rating</p>
                                <p>Power reservoir for 7 days</p>
                                <p>Energy harvesting solar</p>
                                <p>5 hours charge time from 0-100 in bright sunlight</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-24 section bg-white flex items-center">
                <div className="w-4/5 mx-auto">
                    <div className="title mb-6">
                        <p className="text-gray-600 text-xl">Who we are</p>
                        <h1 className="text-3xl font-semibold">eNano: Delivering Technology-Driven Solutions</h1>
                    </div>
                    <div className="description-container flex flex-col md:flex-row items-start gap-6">
                        <img 
                            src="/ourTeam.png" 
                            className="w-full md:w-3/6 h-auto object-cover rounded-md mb-4 md:mb-0" 
                        />
                        <div className="description flex flex-col gap-4 md:w-3/5">
                            <h1 className="text-2xl font-semibold">Our story</h1>
                            <p className="text-gray-700">
                                At eNano Technologies, we’re redefining how you connect with the items that matter most. Our innovative nano technology, like the eNano Tag, offers simple yet powerful solutions for tracking and protecting your valuables.
                            </p>
                            <p className="text-gray-700">
                                We believe in combining cutting-edge technology with user-friendly design, making our products not just functional, but a seamless part of your everyday life.
                            </p>
                            <button 
                                className="bloo text-white py-2 px-6 rounded-lg mt-4 hover:bg-blue-900 transition duration-200 md:w-4/6 lg:w-4/6"
                                onClick={() => window.location.href='/team'}
                            >
                                Meet Our Team
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section bg-white">
                <div className="signup-form w-3/4">
                    <div className="form">
                        <h1>Sign up for our waitlist</h1>
                        <p>Your Vision, Our Mission:<br/>Let's Shape Success Together.</p>
                        {/*<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdlLGL6yVVLXlu6YlX5hLE0G1rHpMKhy3gd095KlGonzK0cGw/viewform?embedded=true" width="640" height="1489" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>*/}
                        <button className="newsletter text-white font-bold py-2 px-4 rounded border border-white mt-4 hover:bg-blue-900 transition duration-200">
                            <a href='/preorder'>
                                Preorder Now
                            </a>
                        </button>
                    </div>
                </div>
                <div className="bottom">
                    <div className="bottom-content">
                        <h1 className="">Contact us</h1>
                        <div>
                            <a href='https://www.instagram.com/enanotechnologies?igsh=MzRlODBiNWFlZA==' target='_blank'><i class="fa-brands fa-instagram"></i></a>
                            <a href='https://www.linkedin.com/company/enano-technologies/posts/?feedView=all' target='_blank'><i class="fa-brands fa-linkedin"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
